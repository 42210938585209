/* --- Styles --- */

// Packages
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/scss/font-awesome";

// Variables
@import "_variables.scss";

// Mixins
@import "_mixins.scss";

// Core CSS
@import "_layout.scss";

// Particle
@import "particle/_icon.scss";

// Forms
@import "forms/_buttons.scss";
@import "forms/_element.scss";

// Components - box
@import "components/box/_annot.scss";
@import "components/box/_benefit.scss";
@import "components/box/_card.scss";
@import "components/box/_cast.scss";
@import "components/box/_content.scss";
@import "components/box/_claim.scss";
@import "components/box/_contact.scss";
@import "components/box/_intro.scss";
@import "components/box/_note.scss";
@import "components/box/_stake.scss";
@import "components/box/_std.scss";

// Components - crossroad
@import "components/crossroad/_benefit.scss";
@import "components/crossroad/_label.scss";
@import "components/crossroad/_service.scss";
@import "components/crossroad/_std.scss";

// Components - menu
@import "components/menu/_basic.scss";

// Components - form
@import "components/form/_std.scss";

// Reset
@import "_reset.scss";
